define("discourse/plugins/discourse-news/discourse/initializers/news-initializer", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "@ember/object/computed", "discourse-common/lib/raw-templates", "discourse/lib/intercept-click", "discourse/lib/text", "virtual-dom", "@ember/service", "@ember/runloop", "discourse/components/modal/share-topic", "@ember/application", "@ember/template"], function (_exports, _pluginApi, _decorators, _computed, _rawTemplates, _interceptClick, _text, _virtualDom, _service, _runloop, _shareTopic, _application, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: 'news-edits',
    initialize(container) {
      const siteSettings = container.lookup('site-settings:main');
      if (!siteSettings.discourse_news_enabled) return;
      (0, _pluginApi.withPluginApi)('0.8.12', api => {
        var _dec, _dec2, _obj, _dec3, _dec4, _dec5, _obj2, _dec6, _dec7, _obj3, _dec8, _obj4, _dec9, _dec10, _obj5, _dec11, _dec12, _obj6;
        api.modifyClass('controller:discovery', (_dec = (0, _decorators.on)('init'), _dec2 = (0, _decorators.observes)('router.currentRouteName'), (_obj = {
          router: (0, _service.inject)(),
          toggleClass() {
            const route = this.get('router.currentRouteName');
            (0, _runloop.scheduleOnce)('afterRender', () => {
              $('#list-area').toggleClass('news', route === 'news');
            });
          }
        }, (_applyDecoratedDescriptor(_obj, "toggleClass", [_dec, _dec2], Object.getOwnPropertyDescriptor(_obj, "toggleClass"), _obj)), _obj)));
        api.modifyClass('controller:discovery/topics', {
          actions: {
            refresh() {
              const route = this.get('router.currentRouteName');
              if (route === 'news') return;
              return this._super();
            }
          }
        });
        api.modifyClass('component:topic-list', (_dec3 = (0, _decorators.default)('currentRoute'), _dec4 = (0, _decorators.on)('didInsertElement'), _dec5 = (0, _decorators.observes)('newsRoute'), (_obj2 = {
          router: (0, _service.inject)(),
          currentRoute: (0, _computed.alias)('router.currentRouteName'),
          newsRoute(currentRoute) {
            return currentRoute === 'news';
          },
          setupNews() {
            const newsRoute = this.get('newsRoute');
            if (newsRoute) {
              const newsCategoryId = this.siteSettings.discourse_news_category;
              const newsCategory = this.site.get("categoriesList").find(c => c.id === newsCategoryId);
              this.set('category', newsCategory);
              $('body').addClass('news');
            } else {
              $('body').removeClass('news');
            }
          }
        }, (_applyDecoratedDescriptor(_obj2, "newsRoute", [_dec3], Object.getOwnPropertyDescriptor(_obj2, "newsRoute"), _obj2), _applyDecoratedDescriptor(_obj2, "setupNews", [_dec4, _dec5], Object.getOwnPropertyDescriptor(_obj2, "setupNews"), _obj2)), _obj2)));
        api.modifyClass('component:topic-list-item', (_dec6 = (0, _decorators.observes)("topic.pinned"), _dec7 = (0, _decorators.default)('newsRoute'), (_obj3 = {
          newsRoute: (0, _computed.alias)('parentView.newsRoute'),
          renderTopicListItem() {
            if (this.get('newsRoute')) {
              const template = (0, _rawTemplates.findRawTemplate)("list/news-item");
              if (template) {
                this.set("topicListItemContents", (0, _template.htmlSafe)(template(this)));
              }
            } else {
              return this._super();
            }
          },
          showReplies(newsRoute) {
            const siteSettings = this.siteSettings;
            const topicSource = siteSettings.discourse_news_source === 'category';
            const showReplies = siteSettings.discourse_news_show_reply_count;
            return newsRoute && topicSource && showReplies;
          },
          click(e) {
            let t = e.target;
            if (!t) {
              return this._super(e);
            }
            if (t.closest(".share")) {
              (0, _application.getOwner)(this).lookup("service:modal").show(_shareTopic.default, {
                model: {
                  category: this.topic.category,
                  topic: this.topic
                }
              });
              return true;
            }
            return this._super(e);
          }
        }, (_applyDecoratedDescriptor(_obj3, "renderTopicListItem", [_dec6], Object.getOwnPropertyDescriptor(_obj3, "renderTopicListItem"), _obj3), _applyDecoratedDescriptor(_obj3, "showReplies", [_dec7], Object.getOwnPropertyDescriptor(_obj3, "showReplies"), _obj3)), _obj3)));
        api.modifyClass('component:site-header', (_dec8 = (0, _decorators.observes)('currentRoute'), (_obj4 = {
          router: (0, _service.inject)(),
          currentRoute: (0, _computed.alias)('router.currentRouteName'),
          rerenderWhenRouteChanges() {
            this.queueRerender();
          },
          buildArgs() {
            return $.extend(this._super(), {
              currentRoute: this.get('currentRoute')
            });
          }
        }, (_applyDecoratedDescriptor(_obj4, "rerenderWhenRouteChanges", [_dec8], Object.getOwnPropertyDescriptor(_obj4, "rerenderWhenRouteChanges"), _obj4)), _obj4)));
        api.reopenWidget('header-buttons', {
          html(attrs) {
            let buttons = this._super(attrs) || [];
            let className = 'header-nav-link news';
            if (attrs.currentRoute === 'news') {
              className += ' active';
            }
            let linkAttrs = {
              href: '/news',
              label: 'filters.news.title',
              className
            };
            const icon = siteSettings.discourse_news_icon;
            if (icon && icon.indexOf('/') > -1) {
              linkAttrs['contents'] = () => {
                return [(0, _virtualDom.h)('img', {
                  attributes: {
                    src: icon
                  }
                }), (0, _virtualDom.h)('span', I18n.t('filters.news.title'))];
              };
            } else if (icon) {
              linkAttrs['icon'] = icon;
            }
            buttons.unshift(this.attach('link', linkAttrs));
            return buttons;
          }
        });
        api.modifyClass('model:topic', (_dec9 = (0, _decorators.default)("news_body"), _dec10 = (0, _decorators.default)("category"), (_obj5 = {
          escapedNewsBody(newsBody) {
            return (0, _text.emojiUnescape)(newsBody);
          },
          basicCategoryLinkHtml(category) {
            return `<a class="basic-category-link"
                     href="${category.url}"
                     title="${category.name}">
                    ${category.name}
                  </a>`;
          }
        }, (_applyDecoratedDescriptor(_obj5, "escapedNewsBody", [_dec9], Object.getOwnPropertyDescriptor(_obj5, "escapedNewsBody"), _obj5), _applyDecoratedDescriptor(_obj5, "basicCategoryLinkHtml", [_dec10], Object.getOwnPropertyDescriptor(_obj5, "basicCategoryLinkHtml"), _obj5)), _obj5)));
        api.modifyClass('controller:discovery/topics', (_dec11 = (0, _decorators.default)('showSidebarTopics'), _dec12 = (0, _decorators.default)('sidebarTopics'), (_obj6 = {
          showSidebar(showSidebarTopics) {
            return showSidebarTopics && !this.site.mobileView;
          },
          showSidebarTopics(sidebarTopics) {
            return sidebarTopics && siteSettings.discourse_news_sidebar_topic_list;
          }
        }, (_applyDecoratedDescriptor(_obj6, "showSidebar", [_dec11], Object.getOwnPropertyDescriptor(_obj6, "showSidebar"), _obj6), _applyDecoratedDescriptor(_obj6, "showSidebarTopics", [_dec12], Object.getOwnPropertyDescriptor(_obj6, "showSidebarTopics"), _obj6)), _obj6)));
      });
    }
  };
});